
import { PropType, defineComponent, onMounted, ref, watch } from 'vue'
import { mutations } from '@/store'
import axios from '@/axios'
import { DocumentBlockStatistic, DocumentBlockStatisticMeta } from './types'
import { ButtonLoader } from '@/UI/loader'
import { debounce } from 'lodash'
import { IParams } from '@/UI/CastomTable/index.vue'

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<IParams>,
      default: () => {}
    }
  },
  setup (props) {
    const documentBlockStatistic = ref(new DocumentBlockStatistic())

    const documentBlockStatisticMeta = new DocumentBlockStatisticMeta()

    const isLoading = ref(false)

    async function getStatistics () {
      isLoading.value = true

      try {
        const responseData = (await axios.get(documentBlockStatisticMeta.endpoint, { params: props.filters })).data
        documentBlockStatistic.value = documentBlockStatisticMeta.load(responseData)
      } catch (error) {
        mutations.pushNotification('Не удалось получить статистику по документам', true)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      getStatistics()
    })

    const debounceGetStatistics = debounce(getStatistics, 1000)

    watch(() => props.filters.tableParams, () => {
      debounceGetStatistics()
    }, { deep: true })

    return () => (<div class="statistics">
      <ButtonLoader hidden={!isLoading.value}/>
      {!isLoading.value && <>
        <div class="col quantity">
          <h4>Количество документов:</h4>
          <p>{documentBlockStatistic.value.docsCount}</p>
        </div>
        <div class="col sum">
          <h4>Сумма ШК:</h4>
          <p>{documentBlockStatistic.value.countSum}</p>
        </div>
      </>}
    </div>)
  }
})
