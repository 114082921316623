
import { filters4booleanField, filters4ColorsField, filters4dateField, filters4numberField, filters4textAndBooleanField, filters4textField } from '@/UI/CastomTable/Filters.vue'
import CastomTable, { IExpose, IHeader, IParams } from '@/UI/CastomTable/index.vue'
import Checkbox from '@/UI/checkbox'
import axios from '@/axios'
import { useSelectedList } from '@/composables/use-selected-list'
import { getCeleryTask } from '@/core/celery'
import { DefaultSelect, LineLoader } from 'apptimizm-ui'
import moment from 'moment'
import { Ref, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { mutations, store } from '../../store'
import { ToggleType } from './index.vue'
import { DocumentEntity, DocumentMeta, IStatus } from './types'
import { changeTableRow } from '@/composables/use-ws-message-type'
import { colorsConvert, translateColorsName } from '@/core/colors'

interface TableFilter {
  vendorCode: Ref<string>
  barcode: Ref<string>
  zoneCodeStart: Ref<string>
  zoneCodeEnd: Ref<string>
  title: Ref<string>
  readonly tableParams: {
    [code: string]: string
  }
}

interface Icolor {
  name: string
  value: string
}

interface ITableProps {
  table: Ref<IExpose<DocumentEntity>>
  tableFilter: TableFilter
  changeStatus: (item: DocumentEntity, status: IStatus) => void
  additionalActionThroughModal: (e: Event, type: ToggleType) => void
  updateAllParams: (params: IParams) => void
  isShowStatistics: boolean
  actionThroughModal: (action: string, role?: string) => void
  selectedList: ReturnType<typeof useSelectedList>
}

export default ({ table, tableFilter, changeStatus, additionalActionThroughModal, updateAllParams, isShowStatistics, actionThroughModal, selectedList }: ITableProps) => {
  const documentMeta = new DocumentMeta()
  const { push } = useRouter()
  const isMenu = ref(false)
  const isID = ref('')
  const newColors = ref([])
  const menuChoice = ref('')
  const myColor: Ref<string[]> = ref([])
  const coordX = ref(0)
  const coordY = ref(0)
  const isLoading = ref(false)

  const isDownloadInProcess = ref(false)

  const headers: IHeader[] = [
    { name: 'Номер', sortAndFilterKey: 'id', filterTypes: filters4numberField },
    { name: '№ зоны', sortAndFilterKey: 'zone__serial_number', filterTypes: filters4textField },
    { name: 'Название зоны', sortAndFilterKey: 'zone_title', filterTypes: filters4textField },
    { name: 'Подозрительный', sortAndFilterKey: 'suspicious', filterTypes: filters4booleanField },
    { name: 'Статус', sortAndFilterKey: 'status', filterTypes: filters4booleanField },
    { name: 'Роль', sortAndFilterKey: 'employee__roles', filterTypes: filters4textField },
    { name: 'Счетчик', sortAndFilterKey: 'counter_scan_barcode_amount', filterTypes: filters4numberField },
    { name: 'УК', sortAndFilterKey: 'controller_barcode_amount', filterTypes: filters4numberField },
    { name: 'Аудитор', sortAndFilterKey: 'auditor_barcode_amount', filterTypes: filters4textAndBooleanField },
    { name: 'Аудитор УК', sortAndFilterKey: 'auditor_controller_barcode_amount', filterTypes: filters4textAndBooleanField },
    { name: 'Внешний аудитор', sortAndFilterKey: 'auditor_external_task__result', filterTypes: filters4textAndBooleanField },
    { name: 'Сотрудник склада', sortAndFilterKey: 'storage_task__result', filterTypes: filters4numberField },
    { name: 'Начало аудита', sortAndFilterKey: 'start_audit_date', filterTypes: filters4dateField },
    { name: 'Окончание аудита', sortAndFilterKey: 'end_audit_date', filterTypes: filters4dateField },
    { name: '№ ТСД', sortAndFilterKey: 'tsd_number', filterTypes: filters4numberField },
    { name: 'ID', sortAndFilterKey: 'employee__serial_number', filterTypes: filters4numberField },
    { name: 'Пользователь', sortAndFilterKey: 'employee_name', filterTypes: filters4textField },
    { name: 'Цвет', sortAndFilterKey: 'colors', filterTypes: filters4ColorsField }
  ]

  function selectArea (e: MouseEvent, item: DocumentEntity) {
    e.preventDefault()

    if (e.shiftKey) {
      selectedList.addRange(item, table.value.items)
      return
    }

    if (e.altKey) {
      selectedList.toggleItem(item)
      return
    }

    selectedList.toggleOneItem(item)
  }

  const selectValues: IStatus[] = [
    { name: 'Не готов', value: 'not_ready' },
    { name: 'Готов', value: 'ready' }
  ]

  const downloadDoc = async (e: Event) => {
    e.stopPropagation()
    isDownloadInProcess.value = true

    const result = (await axios.post(documentMeta.endpoint + 'batch-discrepancy-report/', { document_ids: selectedList.itemsId.value })).data

    const celaryResult = (await getCeleryTask(result.task_id)).data
    if (celaryResult.result) {
      window.open(celaryResult.result)
      mutations.pushNotification('Отчет сформирован')
    } else mutations.pushNotification('Ошибка формирования отчета', true)

    isDownloadInProcess.value = false
  }

  const backgroundColor = (newItem: DocumentEntity) => {
    if (myColor.value.length && myColor.value.includes(newItem.id)) {
      newItem.color = myColor.value[1]
    }

    return colorsConvert(newItem.color)
  }

  const subMenuColor = (colorVal: string) => {
    if (!colorVal) return

    return colorsConvert(colorVal)
  }

  const clickRightButton = async (event: MouseEvent, item: DocumentEntity) => {
    event.preventDefault()

    if (!selectedList.itemsId.value.includes(item.id)) {
      selectedList.toggleOneItem(item)
    }
    const block = document.getElementById('myTable')

    const x = event.pageX
    let y = event.pageY

    if (block && y > block.offsetHeight) y = block.offsetHeight - 20

    coordX.value = x
    coordY.value = y

    isMenu.value = !isMenu.value
    isID.value = item.id

    const { data } = await axios.get(documentMeta.endpoint + 'colors/')
    newColors.value = data
  }

  const mouseOv = (name: string) => {
    menuChoice.value = name
  }

  const selectColor = async (e: Event, choiceColor: string) => {
    e.preventDefault()
    e.stopPropagation()
    myColor.value = []

    if (!selectedList.itemsId.value.length) return

    const { data } = await axios.post(documentMeta.endpoint + 'batch-color-changing/', { color: choiceColor, document_ids: selectedList.itemsId.value })

    const processing = ref<DocumentEntity[]>([])
    if (data.results) {
      processing.value = data.results.map((item: any) => {
        return documentMeta.load(item)
      }).map((item: DocumentEntity) => {
        if (!item) return
        myColor.value.push(item.id, item.color)
        backgroundColor(item)
      })
    }

    isMenu.value = false
  }

  const resetColor = async (event: Event) => {
    event.stopPropagation()
    myColor.value = []

    if (!selectedList.itemsId.value.length) return

    await axios.post(documentMeta.endpoint + 'batch-reset-color/', { document_ids: selectedList.itemsId.value })

    isMenu.value = false
  }

  const onSelectUk = async (id: string, taskId: string) => {
    isLoading.value = true
    axios.post(documentMeta.endpoint + id + '/set-controller/', { task: taskId })
      .then(({ data }) => {
        if (!data.errors) table.value.reload()
      })
      .finally(() => { isLoading.value = false })
  }

  const getUser = (employee: any): string => {
    if (!employee?.username) return '(Пользователь удалён)'
    else if (employee.isDeleted) return `${employee.username} (Удалён)`
    else return employee.username
  }

  const rolesForChangeSpec: { name: string, value: string }[] = [
    { name: 'Аудитор', value: 'auditor' }
    // { name: 'Внешний Аудитор', value: 'auditor_external' }
  ]

  watch(isID, () => mouseOv(''))

  watch(() => store.messagesWebSocket, async (v) => {
    v.map(async mess => {
      if (mess.type.includes('_document')) changeTableRow(table, mess)
      await nextTick()
    })
  }, { deep: true })

  const line = (item: DocumentEntity, maxWidthOfColumns?: number[]) => {
    return (
      <tr
        class={['document-item', 'main', {
          selected: selectedList.itemsId.value.includes(item.id)
        }]}
        style={{ background: !selectedList.itemsId.value.includes(item.id) ? backgroundColor(item) : '#cbb69b' }}
        onContextmenu={(e: MouseEvent) => clickRightButton(e, item) }
        onDblclick={() => push({ name: 'documentation-id', params: { id: item.id } })}
        onClick={(e: MouseEvent) => selectArea(e, item)}
      >
        <td style={{ maxWidth: `${maxWidthOfColumns?.[0]}px` }}>{item.fakeId}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[1]}px` }}>
          {item.zone.serialNumber}
          {isMenu.value && isID.value === item.id
            ? <div class="table-context menu" style={{ left: coordX.value + 'px', top: coordY.value + 'px' }}>
              <div class='menu-element' onMouseover={() => mouseOv('changeStatus')} onClick={(e: Event) => {
                e.stopPropagation()
                actionThroughModal('changeStatus')
              }}>
                <div class="menu-element-text">Изменить статус</div>
              </div>
              {item.auditorDiscrepancy || item.auditorExternalDiscrepancy
                ? <div class='menu-element' onMouseover={() => mouseOv('downloadReport')} onClick={(e: Event) => downloadDoc(e)}>
                  <img class="menu-element-icon" style={{ display: isDownloadInProcess.value ? 'block' : 'none' }} src={require('@/assets/image/warning.svg')}/>
                  <div class="menu-element-text">Отчет по расхождениям</div>
                </div>
                : ''
              }
              <div class={{ 'menu-element': true, disableElement: typeof item.auditorScan === 'undefined' }} onMouseover={() => mouseOv(typeof item.auditorScan === 'undefined' ? '' : 'changeSpec')}>Замена спецификации</div>
              <div class='menu-element' onMouseover={() => mouseOv('changeYK')}>Выбрать Ук</div>
              <div class='menu-element' onMouseover={() => mouseOv('printDoc')} onClick={(e: Event) => additionalActionThroughModal(e, 'print')}>Печать документов</div>
              <div class='menu-element' onMouseover={() => mouseOv('changeColor')}>Выделить цветом</div>
              <div class='menu-element' onMouseover={() => mouseOv('task')} onClick={(e: Event) => additionalActionThroughModal(e, 'issusing')}>Выдать задание</div>
            </div>
            : null
          }
          {isMenu.value && isID.value === item.id && menuChoice.value === 'changeColor'
            ? <div class='sub-menu menu' style={{ left: coordX.value + 205 + 'px', top: coordY.value + 'px' }}>
              {newColors.value.map((subMenu: Icolor) =>
                <div class='menu-element submenu-element' onClick={(e) => selectColor(e, subMenu.value)}>
                  <span class='sub-element' style={{ background: subMenuColor(subMenu.value) }}></span>
                  {subMenu.name}</div>
              )}
              <div class='menu-element submenu-element' onClick={(e) => resetColor(e)}>Сбросить цвет</div>
            </div>
            : null
          }

          {isMenu.value && isID.value === item.id && menuChoice.value === 'changeSpec'
            ? <div
              class='sub-menu menu'
              style={{
                left: coordX.value + 205 + 'px',
                top: (item.auditorDiscrepancy || item.auditorExternalDiscrepancy)
                  ? coordY.value + 64 + 'px' : coordY.value + 32 + 'px'
              }}
            >
              {rolesForChangeSpec.map((role: { name: string, value: string }) =>
                <div class='menu-element submenu-element' onClick={(e) => {
                  e.stopPropagation()
                  actionThroughModal('changeSpec', role.value)
                }}
                >{role.name}</div>

              )}
            </div>
            : null
          }
          {isMenu.value && isID.value === item.id && menuChoice.value === 'changeYK' && item.chooseControllers
            ? <div
              class='sub-menu menu'
              style={{
                left: coordX.value + 205 + 'px',
                top: (item.auditorDiscrepancy || item.auditorExternalDiscrepancy)
                  ? coordY.value + 96 + 'px' : coordY.value + 64 + 'px'
              }}
            >
              {isLoading.value && <LineLoader/>}
              {item.chooseControllers.map(controller =>
                <div
                  class="menu-element submenu-element"
                  onClick={(e: Event) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onSelectUk(item.id, controller.task)
                  }}
                >
                  <div class='submenu-element-yk'>{controller.username}</div>
                  <div class='submenu-element left-border'>{controller.result}</div>
                </div>
              )}
            </div>
            : null
          }
        </td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[2]}px` }}>{item.zone.name}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[3]}px` }} class="center">
          <Checkbox modelValue={item.suspicious} onClick={() => {}}/>
        </td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[4]}px` }} class={{
          select: true,
          ready: item.status.value === 'ready'
        }} onClick={(e: Event) => e.stopPropagation()}>
          <DefaultSelect
            modelValue={item.status}
            idKey="value"
            titleKey="name"
            onValueChange={(v: { id: 'not_ready' | 'ready', name: string }) => { changeStatus(item, { name: v.name, value: v.id }) }}
            items={selectValues.map((v) => ({ id: v.value, name: v.name }))}
          />
        </td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[5]}px` }}>{documentMeta.getRolesName(item.employee?.roles as unknown as string[]) || '(пользователь удалён)'}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[6]}px` }}>{item.counterScan}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[7]}px` }} class="hover">
          <div class="row cell">
            <div>{item.counterController}</div>
          </div>
        </td>

        <td style={{ maxWidth: `${maxWidthOfColumns?.[8]}px` }} class="hover">
          <div class="row cell">
            {item.auditorDiscrepancy
              ? <div class="icon-hover">
                <img class="icon" src={require('@/assets/image/warning.svg')}/>
                <div class="table-hover">Есть расхождение</div>
              </div>
              : <div class="icon-hover"/>
            }
            <div>{item.auditorScan}</div>
          </div>
        </td>

        <td style={{ maxWidth: `${maxWidthOfColumns?.[9]}px` }} class="hover">
          <div class="row cell">
            {item.auditorControllerDiscrepancy
              ? <div class="icon-hover">
                <img class="icon" src={require('@/assets/image/warning.svg')}/>
                <div class="table-hover">Есть расхождение</div>
              </div>
              : <div class="icon-hover"/>
            }
            <div>{item.auditorController}</div>
          </div>
        </td>

        <td style={{ maxWidth: `${maxWidthOfColumns?.[10]}px` }} class="hover">
          <div class="row cell">
            {item.auditorExternalDiscrepancy
              ? <div class="icon-hover">
                <img class="icon" src={require('@/assets/image/warning.svg')}/>
                <div class="table-hover">Есть расхождение</div>
              </div>
              : <div class="icon-hover"/>
            }
            <div>{item.auditorExternal}</div>
          </div>
        </td>

        <td style={{ maxWidth: `${maxWidthOfColumns?.[11]}px` }}>{item.storage}</td>

        <td style={{ maxWidth: `${maxWidthOfColumns?.[12]}px` }}>{moment(item.startAuditDate).locale('ru').format('LLL')}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[13]}px` }}>{moment(item.endAuditDate).locale('ru').format('LLL')}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[14]}px` }}>{item.tsdNumber}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[15]}px` }}>{item.employee?.serialNumber || '(пользователь удалён)'}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[16]}px` }}>{getUser(item.employee)}</td>
        <td style={{ maxWidth: `${maxWidthOfColumns?.[17]}px` }}><div style='width: 180px; text-align: center;'>{translateColorsName(item.color)}</div></td>
      </tr>
    )
  }

  return (
    <div
      id='myTable'
      class='table-wrapper'
      onPointerleave={() => {
        isMenu.value = false
        isID.value = ''
      }}
      onClick={() => {
        isMenu.value = false
        isID.value = ''
      }}
    >
      <CastomTable
        class={{ 'show-statistics': isShowStatistics }}
        meta={documentMeta}
        params={tableFilter.tableParams}
        ref={table}
        line={line}
        headers={headers}
        updateAllParams={updateAllParams}
        perPage={'1000'}
      />
    </div>
  )
}
