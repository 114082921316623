
import { DefaultInput, NumberInput, ModalForm } from 'apptimizm-ui'
import { ISelectValue, ProjectMeta } from '../projects/types'
import axios from '@/axios'
import { onMounted, Ref, ref, defineComponent, onBeforeUnmount, watch, computed } from 'vue'
import Table from './table.vue'
import { store, mutations } from '@/store/index'
import { RouterView, useRoute } from 'vue-router'
import { DocumentEntity, DocumentMeta, IStatus } from './types'
import IssusingTask from '../areas/issuingTask'
import ReportInZones from '../areas/reportInZones.vue'
import { FormFields } from '../areas/types'
import { IExpose, IParams } from '@/UI/CastomTable/index.vue'
import Statistics from './statistics.vue'
import { host } from '@/const'
import { useSelectedList } from '@/composables/use-selected-list'
import { getAllList } from '@/utils'

export type ToggleType = 'issusing'|'print'|string

export default defineComponent({
  setup () {
    const projecttMeta = new ProjectMeta()
    const documentMeta = new DocumentMeta()
    const table = ref(null) as unknown as Ref<IExpose<DocumentEntity>>
    const route = useRoute()
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const form = ref(new FormFields())
    const modalPopup = ref({ show: false, type: '' as ToggleType })

    const toggle = (type: ToggleType = '') => {
      modalPopup.value.show = !modalPopup.value.show
      modalPopup.value.type = type
      form.value.reset()
    }

    const closeModal = () => { toggle() }

    const idsZonesForReport = ref<string[]>([])

    const selectedList = useSelectedList<DocumentEntity>()
    const roleChoices: Ref<ISelectValue[]> = ref([])

    type ActionDocType = 'changeSpec' | 'changeStatus'

    async function selectDocumentAll (e: KeyboardEvent) {
      if (e.ctrlKey && e.code === 'KeyA') {
        selectedList.items.value = await getAllList(documentMeta, tableFilter.tableParams)
      }
    }

    watch(() => table.value?.items, () => {
      selectedList.items.value = []
    })

    const tableFilter = {
      vendorCode: ref(''),
      barcode: ref(''),
      zoneCodeStart: ref(''),
      zoneCodeEnd: ref(''),
      title: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.vendorCode.value) res.vendor_code = this.vendorCode.value
        if (this.barcode.value) res.barcode = this.barcode.value
        if (this.zoneCodeStart.value) res.zone_code_start = this.zoneCodeStart.value
        if (this.zoneCodeEnd.value) res.zone_code_end = this.zoneCodeEnd.value
        if (this.title.value) res.product_title = this.title.value

        return res
      }
    }

    const changeStatus = async (item: DocumentEntity, status: IStatus): Promise<void> => {
      if (!status.value || status.value === item.status.value) return
      const { data } = await axios.patch(documentMeta.endpoint + item.id + '/', { status: status.value })
      if (data.errors) mutations.pushNotification(`Ошибка при изменении статуса ${item.zone.name}`, true)
      else {
        item.status = {
          name: data.status === 'ready' ? 'Готов' : 'Не готов',
          value: data.status
        }
        mutations.pushNotification(`Статус ${item.zone.name} изменен`)
      }
    }

    const addActionThroughModal = (e: Event, typeOperation: ToggleType) => {
      if (typeOperation) {
        idsZonesForReport.value = []
        selectedList.items.value.map((item: DocumentEntity) => {
          if (item.zone?.id) idsZonesForReport.value.push(item.zone.id)
          return item
        })
      }

      toggle(typeOperation)
      e.stopPropagation()
    }

    onMounted(async () => {
      document.addEventListener('keydown', selectDocumentAll)

      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projecttMeta.load((await axios.get(`${projecttMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }

      roleChoices.value = (await axios.get(`${host}/api/v1/task/types_choices/`)).data
    })

    // Управление блоком со статистикой
    const isShowStatistics = ref(false)

    function toggleStatistics () {
      isShowStatistics.value = !isShowStatistics.value
    }

    const allParams = ref<IParams>({})

    function setAllParams (params: IParams) {
      allParams.value = params
    }
    //

    // функционал изменения спецификации
    const isModalActionChange = ref(false)
    const changeRoleSpec = ref<{role: string, action: ActionDocType|string }>({
      role: '',
      action: ''
    })

    const messageModal = computed(() => {
      const modalInfo = {
        head: '',
        message: ''
      }

      if (changeRoleSpec.value.action === 'changeSpec') {
        modalInfo.head = 'Подтверждение'
        modalInfo.message = 'Вы уверены, что хотите изменить спецификацию?'
      } else {
        modalInfo.head = 'Изменить статус'
        modalInfo.message = 'Вы действительно хотите изменить статус документов?'
      }

      return modalInfo
    })

    function openModalConfirmChangeSpec (action: string, role?: string) {
      changeRoleSpec.value = { action: action, role: role || '' }
      isModalActionChange.value = true
    }

    function closeConfirmModal () {
      changeRoleSpec.value = { action: '', role: '' }
      isModalActionChange.value = false
    }

    async function changeGroupSpecification () {
      try {
        await axios.post(documentMeta.endpoint + 'batch-specification-replacement/', { document_ids: selectedList.itemsId.value, source: changeRoleSpec.value.role })
        mutations.pushNotification('Спецификации изменены')

        table.value.reload()
      } catch (error) {
        mutations.pushNotification('Ошибка при изменении спецификаций', true)
      }
    }

    async function changeGroupStatus () {
      try {
        await axios.post(documentMeta.endpoint + 'batch-status-inverting/', { document_ids: selectedList.itemsId.value })
        mutations.pushNotification('Статус изменён')

        table.value.reload()
      } catch (error) {
        mutations.pushNotification('Ошибка при изменении статуса', true)
      }
    }

    async function actionChangeDocuments () {
      try {
        switch (changeRoleSpec.value.action) {
          case 'changeSpec':
            await changeGroupSpecification()
            break
          case 'changeStatus':
            await changeGroupStatus()
            break
          default:
            break
        }
      } catch (error) {
        mutations.pushNotification('Ошибка при выполнении операции', true)
      }

      changeRoleSpec.value = { action: '', role: '' }
      closeConfirmModal()
    }

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', selectDocumentAll)
    })

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Документы</h1>
          <div class="page-top-panel-row mt-5">
            <label>
              <DefaultInput
                class={tableFilter.vendorCode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Код товара"
                onValueChange={(v :string) => { tableFilter.vendorCode.value = v }}
                modelValue={tableFilter.vendorCode.value}
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.barcode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Штрих-код товара"
                onValueChange={(v :string) => { tableFilter.barcode.value = v }}
                modelValue={tableFilter.barcode.value}
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.title.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Название"
                onValueChange={(v :string) => { tableFilter.title.value = v }}
                modelValue={tableFilter.title.value}
              />
            </label>
            <label>
              <DefaultInput
                class={`search ${!tableFilter.zoneCodeStart.value ? 'hide-zero' : 'input-placeholder-up'}`}
                placeholder="Зоны с..."
                modelValue={tableFilter.zoneCodeStart.value}
                onValueChange={(v: string) => { tableFilter.zoneCodeStart.value = v } }
              />
            </label>
            <label>
              <DefaultInput
                class={`search ${!tableFilter.zoneCodeEnd.value ? 'hide-zero' : 'input-placeholder-up'}`}
                placeholder="Зоны по..."
                modelValue={tableFilter.zoneCodeEnd.value}
                onValueChange={(v: string) => { tableFilter.zoneCodeEnd.value = v } }
              />
            </label>
          </div>
        </div>

        <div class='document-content'>
          <div class={{
            layout: true,
            'layout--height': route.params.id
          }}>
            {store.currentProject.id && <Table
              table={table}
              tableFilter={tableFilter}
              changeStatus={changeStatus}
              selectedList={selectedList as any}
              additionalActionThroughModal={addActionThroughModal}
              updateAllParams={setAllParams}
              isShowStatistics={isShowStatistics.value}
              actionThroughModal={openModalConfirmChangeSpec}
            />}
          </div>
          {!!store.currentProject.id && isShowStatistics.value && <Statistics filters={allParams.value}/>}
          <RouterView/>
          {!!store.currentProject.id && <div class="bottom-row">
            <button
              class="button-statistics mr-3"
              onClick={toggleStatistics}
            >Статистика</button>
          </div>}
        </div>

        <div>
          {modalPopup.value.show && modalPopup.value.type === 'issusing'
            ? <IssusingTask
              selectedAreas={idsZonesForReport.value}
              close={closeModal}
              roles={roleChoices.value}
            />
            : '' }

          {modalPopup.value.show && modalPopup.value.type === 'print'
            ? <ReportInZones close={closeModal} zoneIds={idsZonesForReport.value}/>
            : ''
          }
          {isModalActionChange.value
            ? <ModalForm
              cancel={closeConfirmModal}
              confirm={() => {}}
              header={messageModal.value.head}
              slots={{
                default: () => (<p class="modal-text mt-3">{messageModal.value.message}</p>),
                buttons: () => (<div class="mt-3">
                  <div class="row" style={'justify-content: space-between'}>
                    <button onClick={closeConfirmModal} class="default-button">Нет</button>
                    <button onClick={actionChangeDocuments} class="danger-button">Да</button>
                  </div>
                </div>)

              }}
            />
            : ''
          }

        </div>
      </div>
    )
  }
})
